import React, { useState } from 'react';
import { Modal } from 'reactstrap';

import { replaceFromString } from 'utils';
import { REST_URL } from 'configs/restAPI';

export const Image = (props) => {
    let {
        path,
        image,
        size,
        marginRight,
        label,

        width,
        maxWidth,
        height,
        bottom,
        className,
        inFocus,
        showFull,
    } = props;

    const [ID] = useState(Math.floor(Math.random() * 100000));
    const [openModal, setOpenModal] = useState(false);

    const getStyle = () => {
        let result = {
            objectFit: 'cover',
            borderRadius: '1.5rem',
        };
        if (size === 'icon') {
            result = {
                ...result,
                height: '1.5rem',
                width: '1.5rem',
                border: '1px solid lavender',
            };
        } else {
            result = {
                ...result,
                height: '200px',
                width: '200px',
                border: '2px solid lavender',
            };
        }
        if (marginRight) {
            result = { ...result, marginRight: '5px' };
        }
        return result;
    };

    if ([undefined, null].includes(image)) {
        return (
            <>
                <div
                    id={`generic-image-${ID}`}
                    style={{
                        ...getStyle(),
                        backgroundColor: 'lavender',
                    }}
                ></div>
                {label && <span>{label}</span>}
            </>
        );
    }

    const complexID = `generic-image-${replaceFromString(
        path ? path : '',
        ['/', '.', ':', ' ', '(', ')'],
        '-'
    )}-${ID}`;

    const style = {
        ...(width ? { width } : {}),
        ...(height ? { height } : {}),
        ...(maxWidth ? { maxWidth } : {}),
        ...(inFocus ? {} : {}),
    };

    return (
        <div
            className={`generic-image-container ${className ? className : ''} ${
                bottom ? 'img-bottom-cont' : ''
            }`}
            id={complexID}
            style={style}
        >
            <img
                alt={size === 'icon' ? 'icon' : 'avatar'}
                style={getStyle()}
                src={
                    path
                        ? `${REST_URL}/${path}${size === 'icon' ? '-300.jpg' : '.jpg'}?a=${ID}`
                        : image
                }
                onClick={() => {
                    if (showFull) {
                        setOpenModal(true);
                    }
                }}
            />
            {label && <span>{label}</span>}

            {showFull && (
                <Modal
                    size="xxl"
                    className="m-t-50"
                    isOpen={openModal}
                    toggle={() => setOpenModal(false)}
                >
                    <img alt="some-random-alt" src={image} />
                </Modal>
            )}
        </div>
    );
};
