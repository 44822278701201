import React, { useState } from 'react';
import { Table, Row, Col, Modal } from 'reactstrap';
import IngredientCategoryForm from './form';
import { MaterialSymbol } from 'react-material-symbols';
import { REST_URL } from 'configs/restAPI';
import DeleteIngredientCategoryForm from './deleteForm';

export default function IngredientCategories(props) {
    const {
        ingredientCategories,
        languages,
        onCreateIngredientCategory,
        onUpdateIngredientCategory,
        onRemoveIngredientCategory,
    } = props;

    const [editIngredientCategory, setEditIngredientCategory] = useState(null);
    const [deleteIngredientCategory, setDeleteIngredientCategory] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const toggle = () => {
        setModalOpen(!modalOpen);
    };

    const sortedIngredientCategories = [...ingredientCategories].sort((i1, i2) =>
        i1.order < i2.order ? -1 : 1
    );

    return (
        <>
            <Table hover>
                <thead>
                    <tr>
                        <th width={'50px'}>Ikona</th>
                        {languages.map((lang) => (
                            <th key={lang.value}>{lang.label}</th>
                        ))}
                        <th width={'5%'}>Akcia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr key="add-ingredientCategory-btn">
                        <td
                            colSpan={2 + languages.length}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setEditIngredientCategory(null);
                                toggle();
                            }}
                        >
                            + Kategória
                        </td>
                    </tr>
                    {sortedIngredientCategories.map((ingredientCategory) => (
                        <tr
                            key={ingredientCategory.id}
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            <td>
                                <img
                                    src={`${REST_URL}/${ingredientCategory.iconPath}-150.jpg?a=${ingredientCategory.updatedAt}`}
                                    alt={`ingredientCategory.id`}
                                    style={{
                                        width: '50px',
                                        height: '50px',
                                        backgroundColor: ingredientCategory.iconPath
                                            ? 'transparent'
                                            : 'gray',
                                    }}
                                />
                            </td>
                            {languages.map((lang) => (
                                <td key={`${ingredientCategory.id}-${lang.value}`}>
                                    {ingredientCategory.translations.find(
                                        (translation) => translation.language.id === lang.value
                                    )?.title ?? '-'}
                                </td>
                            ))}

                            <td>
                                <Row xs="2">
                                    <Col>
                                        <MaterialSymbol
                                            icon={'edit'}
                                            size={24}
                                            grade={-25}
                                            color={'orange'}
                                            style={{
                                                outline: 'none',
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                            }}
                                            onClick={() => {
                                                setEditIngredientCategory(ingredientCategory);
                                                toggle();
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <MaterialSymbol
                                            icon={'cancel'}
                                            size={24}
                                            grade={-25}
                                            color={'red'}
                                            style={{
                                                outline: 'none',
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                            }}
                                            onClick={() => {
                                                setDeleteIngredientCategory(ingredientCategory);
                                                toggle();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Modal
                isOpen={modalOpen}
                toggle={() => {
                    if (modalOpen) {
                        setEditIngredientCategory(null);
                        setDeleteIngredientCategory(null);
                    }
                    toggle();
                }}
            >
                {deleteIngredientCategory && (
                    <DeleteIngredientCategoryForm
                        onClose={() => {
                            setEditIngredientCategory(null);
                            setDeleteIngredientCategory(null);
                            toggle();
                        }}
                        onSave={(data) => {
                            if (window.confirm('Ste si isty?')) {
                                onRemoveIngredientCategory(data);
                                setDeleteIngredientCategory(null);
                                toggle();
                            }
                        }}
                        ingredientCategory={deleteIngredientCategory}
                        replacementOptions={ingredientCategories
                            .filter(
                                (ingredientCategory) =>
                                    ingredientCategory.id !== deleteIngredientCategory.id
                            )
                            .map((ingredientCategory) => ({
                                value: ingredientCategory.id,
                                label: ingredientCategory.translations
                                    .map(
                                        (translation) =>
                                            `${translation.title} (${translation.language.languageCode})`
                                    )
                                    .join(', '),
                            }))}
                    />
                )}
                {!deleteIngredientCategory && (
                    <IngredientCategoryForm
                        onClose={() => {
                            setEditIngredientCategory(null);
                            toggle();
                        }}
                        onSave={(data) => {
                            if (editIngredientCategory) {
                                onUpdateIngredientCategory(data);
                            } else {
                                onCreateIngredientCategory(data);
                            }
                            setEditIngredientCategory(null);
                            toggle();
                        }}
                        ingredientCategory={editIngredientCategory}
                        languages={languages}
                    />
                )}
            </Modal>
        </>
    );
}
