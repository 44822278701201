import React, { useState } from 'react';
import { FormGroup, Label, Form, Button, Card, Row, Col } from 'reactstrap';
import Select from 'react-select';

export default function DeleteCuisineForm(props) {
    const { onClose, onSave, cuisine, replacementOptions } = props;

    const [replacement, setReplacement] = useState(null);

    return (
        <Card style={{ padding: '1em' }}>
            <Row>
                <Form>
                    <FormGroup>
                        <Label htmlFor="cuisine_name">Musíte zvoliť náhradnú kuchyňu</Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={false}
                            isSearchable={true}
                            name="cuisine_name"
                            options={replacementOptions}
                            value={replacement}
                            onChange={(e) => {
                                setReplacement(e);
                            }}
                        />
                    </FormGroup>
                </Form>
            </Row>

            <Row xs="2">
                <Col>
                    <Button color="secondary" block onClick={onClose}>
                        Zrušiť
                    </Button>
                </Col>
                <Col>
                    <Button
                        color="primary"
                        block
                        disabled={!replacement}
                        onClick={() => {
                            onSave({
                                id: cuisine.id,
                                replacementId: replacement.value,
                            });
                        }}
                    >
                        Uložiť
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}
