import React from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import MealTimes from './list';
import { Col, Row, Spinner } from 'reactstrap';
import {
    MEAL_TIMES,
    MEAL_TIMES_SUBSCRIPTION,
    ADD_MEAL_TIME,
    UPDATE_MEAL_TIME,
    DELETE_MEAL_TIME,
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
} from 'queries';

export default function MealTimesContainer() {
    const [addMealTime] = useMutation(ADD_MEAL_TIME);
    const [updateMealTime] = useMutation(UPDATE_MEAL_TIME);
    const [deleteMealTime] = useMutation(DELETE_MEAL_TIME);

    const {
        data: mealTimesData,
        loading: mealTimesLoading,
        refetch: mealTimesRefetch,
    } = useQuery(MEAL_TIMES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(MEAL_TIMES_SUBSCRIPTION, {
        onData: () => {
            mealTimesRefetch();
        },
    });

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const onCreateMealTime = (data) => {
        addMealTime({
            variables: {
                available: data.available,
                order: data.order,
                translations: data.translations.map((translation) => ({
                    title: translation.translation,
                    languageId: translation.language.id,
                })),
            },
        });
    };

    const onUpdateMealTime = (data) => {
        updateMealTime({
            variables: {
                updateMealTimeId: data.id,
                available: data.available,
                order: data.order,
                translations: data.translations.map((translation) => ({
                    title: translation.translation,
                    languageId: translation.language.id,
                })),
            },
        });
    };
    const onRemoveMealTime = (id) => {
        deleteMealTime({
            variables: {
                deleteMealTimeId: id,
            },
        });
    };

    if (mealTimesLoading || languagesLoading) {
        return (
            <Row
                style={{
                    paddingTop: '1em',
                }}
            >
                <Col>
                    <Spinner color="primary"></Spinner>
                </Col>
            </Row>
        );
    }

    return (
        <Col>
            <MealTimes
                mealTimes={mealTimesData && mealTimesData.mealTimes ? mealTimesData.mealTimes : []}
                languages={
                    languagesData && languagesData.languages
                        ? languagesData.languages.map((lang) => ({
                              ...lang,
                              label: lang.title,
                              value: lang.id,
                          }))
                        : []
                }
                onCreateMealTime={onCreateMealTime}
                onUpdateMealTime={onUpdateMealTime}
                onRemoveMealTime={onRemoveMealTime}
            />
        </Col>
    );
}
