import { gql } from '@apollo/client';

export const INGREDIENTS_SUBSCRIPTION = gql`
    subscription ingredientsSubscription {
        ingredientsSubscription
    }
`;

export const INGREDIENTS = gql`
    query ingredients($search: String, $languageId: Int, $limit: Int, $offset: Int) {
        ingredients(search: $search, languageId: $languageId, limit: $limit, offset: $offset) {
            id
            conversionsVerified
            translations {
                title
                language {
                    id
                }
            }
        }
    }
`;

export const INGREDIENT = gql`
    query ingredient($id: Int!) {
        ingredient(id: $id) {
            id
            units
            conversionsVerified
            translations {
                title
                language {
                    id
                }
            }
            conversions {
                fromUnit
                multiplier
            }
            category {
                id
                translations {
                    language {
                        id
                    }
                    title
                }
            }
        }
    }
`;

export const ADD_INGREDIENT = gql`
    mutation addIngredient(
        $units: [IngredientUnitEnum!]!
        $conversions: [IngredientConversionInput!]!
        $translations: [IngredientTranslationInput!]!
        $conversionsVerified: Boolean
        $intoleranceIds: [Int!]!
        $categoryId: Int!
    ) {
        addIngredient(
            units: $units
            conversions: $conversions
            translations: $translations
            conversionsVerified: $conversionsVerified
            intoleranceIds: $intoleranceIds
            categoryId: $categoryId
        ) {
            id
        }
    }
`;

export const UPDATE_INGREDIENT = gql`
    mutation updateIngredient(
        $id: Int!
        $units: [IngredientUnitEnum!]!
        $conversions: [IngredientConversionInput!]
        $translations: [IngredientTranslationInput!]
        $conversionsVerified: Boolean
        $intoleranceIds: [Int!]
        $categoryId: Int
    ) {
        updateIngredient(
            id: $id
            units: $units
            conversions: $conversions
            translations: $translations
            conversionsVerified: $conversionsVerified
            intoleranceIds: $intoleranceIds
            categoryId: $categoryId
        ) {
            id
        }
    }
`;

export const DELETE_INGREDIENT = gql`
    mutation deleteIngredient($id: Int!) {
        deleteIngredient(id: $id) {
            id
        }
    }
`;
