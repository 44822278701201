import { gql } from '@apollo/client';

export const MEAL_TIMES_SUBSCRIPTION = gql`
    subscription mealTimesSubscription {
        mealTimesSubscription
    }
`;

export const MEAL_TIMES = gql`
    query mealTimes($available: Boolean) {
        mealTimes(available: $available) {
            available
            id
            order
            translations {
                title
                language {
                    id
                }
            }
        }
    }
`;

export const ADD_MEAL_TIME = gql`
    mutation addMealTime(
        $available: Boolean
        $order: Int
        $translations: [MealTimeTranslationInput!]!
    ) {
        addMealTime(available: $available, order: $order, translations: $translations) {
            id
        }
    }
`;

export const UPDATE_MEAL_TIME = gql`
    mutation updateMealTime(
        $updateMealTimeId: Int!
        $available: Boolean
        $translations: [MealTimeTranslationInput!]!
        $order: Int
    ) {
        updateMealTime(
            id: $updateMealTimeId
            available: $available
            translations: $translations
            order: $order
        ) {
            id
        }
    }
`;

export const DELETE_MEAL_TIME = gql`
    mutation deleteMealTime($deleteMealTimeId: Int!) {
        deleteMealTime(id: $deleteMealTimeId)
    }
`;
