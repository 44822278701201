import React, { useEffect, useState, useMemo } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { FormGroup, Label, Input, Form, Button, Card, Row, Col, Spinner, Table } from 'reactstrap';
import Select from 'react-select';
import translate from 'translate';
import { INGREDIENT_CATEGORIES, INGREDIENT_CATEGORIES_SUBSCRIPTION } from 'queries';

const UNITS = [
    {
        label: 'Gram',
        value: 'gram',
        isFixed: true,
    },
    {
        label: 'Piece',
        value: 'piece',
        defaultMultiplier: 1,
    },
    {
        label: 'Liter',
        value: 'liter',
        defaultMultiplier: 1000,
    },
    {
        label: 'Teaspoon',
        value: 'teaspoon',
        defaultMultiplier: 1,
    },
    {
        label: 'Pinch',
        value: 'pinch',
        defaultMultiplier: 1,
    },
    {
        label: 'Tablespoon',
        value: 'tablespoon',
        defaultMultiplier: 1,
    },
    {
        label: 'Handful',
        value: 'handful',
        defaultMultiplier: 1,
    },
];

export default function IngredientForm(props) {
    const { onClose, onSave, ingredientId, ingredientData, ingredientLoading, languages } = props;

    const [units, setUnits] = useState([
        {
            label: 'Gram',
            value: 'gram',
            isFixed: true,
        },
    ]);

    const [category, setCategory] = useState(null);
    const [conversions, setConversions] = useState([]);
    const [conversionsVerified, setConversionsVerified] = useState(false);
    const [translations, setTranslations] = useState([]);
    const [translating, setTranslating] = useState(false);
    const [originalTranslation, setOriginalTranslation] = useState(null);

    const {
        data: ingredientCategoriesData,
        loading: ingredientCategoriesLoading,
        refetch: ingredientCategoriesRefetch,
    } = useQuery(INGREDIENT_CATEGORIES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(INGREDIENT_CATEGORIES_SUBSCRIPTION, {
        onData: () => {
            ingredientCategoriesRefetch();
        },
    });

    useEffect(() => {
        if (ingredientData && ingredientData.ingredient) {
            setTranslations(
                languages.map((item) => ({
                    language: item,
                    translation:
                        ingredientData.ingredient.translations.find(
                            (translation) => translation.language.id === item.id
                        )?.title ?? '',
                }))
            );
            setUnits(
                ingredientData.ingredient.units.map((unit) => UNITS.find((u) => u.value === unit))
            );
            setConversions(
                ingredientData.ingredient.conversions.map((conversion) => ({
                    multiplier: conversion.multiplier,
                    fromUnit: conversion.fromUnit,
                }))
            );

            setConversionsVerified(ingredientData.ingredient.conversionsVerified ?? false);
            setCategory(
                ingredientData.ingredient.category
                    ? {
                          label: ingredientData.ingredient.category.translations
                              .map(
                                  (translation) =>
                                      `${translation.title} (${translation.language.languageCode})`
                              )
                              .join(', '),
                          value: ingredientData.ingredient.category.id,
                      }
                    : null
            );
        } else if (languages) {
            setTranslations(
                languages.map((item) => ({
                    language: item,
                    translation: '',
                }))
            );

            setUnits([
                {
                    label: 'Gram',
                    value: 'gram',
                    isFixed: true,
                },
            ]);
            setConversions([]);
            setConversionsVerified(false);
            setCategory(null);
        }
    }, [ingredientData, languages]);

    async function getOtherTitles(original) {
        if (!original) {
            return;
        }

        let newTranslations = [...translations];

        for (let index = 0; index < translations.length; index++) {
            const element = translations[index];
            if (element.language.id !== original.language.id) {
                let translatedTitle = await translate(original.translation, {
                    from: original.language.languageCode,
                    to: element.language.languageCode,
                });
                newTranslations[index] = {
                    ...element,
                    translation: translatedTitle,
                };
            }
        }

        setTranslations(newTranslations);
        setTranslating(false);
        setOriginalTranslation(null);
    }

    const ingredientCategoriesOptions = useMemo(() => {
        return ingredientCategoriesData && ingredientCategoriesData.ingredientCategories
            ? ingredientCategoriesData.ingredientCategories.map((ingredientCategory) => ({
                  value: ingredientCategory.id,
                  label: ingredientCategory.translations
                      .map(
                          (translation) =>
                              `${translation.title} (${translation.language.languageCode})`
                      )
                      .join(', '),
              }))
            : [];
    }, [ingredientCategoriesData]);

    if (ingredientLoading || ingredientCategoriesLoading) {
        return (
            <Card style={{ padding: '1em' }}>
                <Spinner color="primary" size="sm" />
            </Card>
        );
    }

    return (
        <Card style={{ padding: '1em' }}>
            <Row>
                <Form>
                    <FormGroup>
                        <Label htmlFor="trending-group-title">Názov</Label>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Jazyk</th>
                                    <th>Preklad</th>
                                </tr>
                            </thead>
                            <tbody>
                                {translations.map((translation) => (
                                    <tr key={translation.language.id}>
                                        <td>{`${translation.language.title} (${translation.language.languageCode})`}</td>
                                        <td>
                                            {translating &&
                                                translation.language.id !==
                                                    originalTranslation.language.id && <Spinner />}
                                            {(!translating ||
                                                translation.language.id ===
                                                    originalTranslation.language.id) && (
                                                <>
                                                    <Input
                                                        id={`ingredient_translation_for_${translation.languageCode}`}
                                                        disabled={translating}
                                                        value={translation.translation}
                                                        onChange={(e) => {
                                                            setTranslations(
                                                                translations.map((item) => {
                                                                    if (
                                                                        item.language.id ===
                                                                        translation.language.id
                                                                    ) {
                                                                        return {
                                                                            ...item,
                                                                            translation:
                                                                                e.target.value,
                                                                        };
                                                                    }
                                                                    return item;
                                                                })
                                                            );
                                                        }}
                                                    />
                                                    {translation.translation.length > 0 && (
                                                        <Button
                                                            color="link"
                                                            onClick={() => {
                                                                setTranslating(true);
                                                                setOriginalTranslation(translation);
                                                                getOtherTitles(translation);
                                                            }}
                                                        >
                                                            Preložiť podľa tohto prekladu
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="ingredient-category">Karegória</Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={false}
                            isSearchable={true}
                            name="ingredient-category"
                            options={ingredientCategoriesOptions}
                            value={category}
                            onChange={(e) => {
                                setCategory(e);
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="trending-group-chefs">Jednotky</Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={units.some((option) => !option.isFixed)}
                            isSearchable={true}
                            isMulti
                            name="trending-group-chefs"
                            options={UNITS}
                            value={units}
                            onChange={(e, actionMeta) => {
                                if (
                                    ['remove-value', 'pop-value'].includes(actionMeta.action) &&
                                    actionMeta.removedValue.isFixed
                                ) {
                                    return;
                                }
                                if ('clear' === actionMeta.action) {
                                    const newUnits = units.filter((option) => option.isFixed);
                                    setUnits(newUnits);
                                    setConversions(
                                        conversions.filter((conversion) =>
                                            newUnits.find(
                                                (option) => option.value === conversion.fromUnit
                                            )
                                        )
                                    );
                                    return;
                                }

                                setUnits(e);
                                if (units.length > e.length) {
                                    const removedUnit = units.find(
                                        (unit) => !e.find((u) => u.value === unit.value)
                                    ).value;
                                    let newConverstions = conversions.filter(
                                        (conversion) => conversion.fromUnit !== removedUnit
                                    );
                                    setConversions(newConverstions);
                                } else if (units.length < e.length) {
                                    const addedUnit = e.find(
                                        (unit) => !units.find((u) => u.value === unit.value)
                                    );
                                    setConversions([
                                        ...conversions,
                                        {
                                            fromUnit: addedUnit.value,
                                            multiplier: addedUnit.defaultMultiplier,
                                        },
                                    ]);
                                }
                            }}
                        />
                    </FormGroup>

                    {units.length >= 2 && (
                        <FormGroup>
                            <Label htmlFor="trending-group-title">Prevody jednotiek</Label>
                            <ul>
                                {conversions.map((conversion) => (
                                    <li
                                        key={`gram-to-${conversion.fromUnit}`}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '8px',
                                        }}
                                    >
                                        <Input
                                            id={`gram-to-${conversion.fromUnit}`}
                                            value={conversion.multiplier}
                                            style={{
                                                width: '100px',
                                                marginLeft: '8px',
                                                marginRight: '8px',
                                            }}
                                            step={0.05}
                                            min={0.05}
                                            type="number"
                                            onChange={(e) => {
                                                setConversions(
                                                    conversions.map((conv) =>
                                                        conv.fromUnit === conversion.fromUnit
                                                            ? {
                                                                  ...conversion,
                                                                  multiplier: e.target.value
                                                                      ? parseFloat(e.target.value)
                                                                      : 0,
                                                              }
                                                            : conv
                                                    )
                                                );
                                            }}
                                        />
                                        <span>{` gramov = 1 ${conversion.fromUnit}`}</span>
                                    </li>
                                ))}
                            </ul>
                        </FormGroup>
                    )}

                    <FormGroup check>
                        <Input
                            id="conversions-verified"
                            type="checkbox"
                            checked={conversionsVerified}
                            onChange={() => {
                                setConversionsVerified(!conversionsVerified);
                            }}
                        />
                        <Label check htmlFor="conversions-verified">
                            Konverzie boli skontrolované
                        </Label>
                    </FormGroup>
                </Form>
            </Row>

            <Row xs="2">
                <Col>
                    <Button color="secondary" block onClick={onClose}>
                        Zrušiť
                    </Button>
                </Col>
                <Col>
                    <Button
                        color="primary"
                        block
                        disabled={
                            translations.some(
                                (translation) => translation.translation.length === 0
                            ) || !category
                        }
                        onClick={() => {
                            onSave({
                                id: ingredientId,
                                units,
                                conversions,
                                translations,
                                conversionsVerified,
                                categoryId: category.value,
                                intoleranceIds: [],
                            });
                        }}
                    >
                        Uložiť
                    </Button>
                </Col>
            </Row>
        </Card>
    );
}
