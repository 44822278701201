import { gql } from '@apollo/client';

export const INGREDIENT_CATEGORIES_SUBSCRIPTION = gql`
    subscription ingredientCategoriesSubscription {
        ingredientCategoriesSubscription
    }
`;

export const INGREDIENT_CATEGORIES = gql`
    query ingredientCategories {
        ingredientCategories {
            id
            updatedAt
            order
            iconPath
            translations {
                title
                language {
                    id
                    languageCode
                }
            }
        }
    }
`;

export const ADD_INGREDIENT_CATEGORY = gql`
    mutation addIngredientCategory(
        $icon: String
        $order: Int!
        $translations: [IngredientCategoryTranslationInput!]!
    ) {
        addIngredientCategory(icon: $icon, order: $order, translations: $translations) {
            id
        }
    }
`;

export const UPDATE_INGREDIENT_CATEGORY = gql`
    mutation updateIngredientCategory(
        $updateIngredientCategoryId: Int!
        $icon: String
        $translations: [IngredientCategoryTranslationInput!]!
        $order: Int
    ) {
        updateIngredientCategory(
            id: $updateIngredientCategoryId
            icon: $icon
            translations: $translations
            order: $order
        ) {
            id
        }
    }
`;

export const DELETE_INGREDIENT_CATEGORY = gql`
    mutation deleteIngredientCategory($deleteIngredientCategoryId: Int!, $newId: Int!) {
        deleteIngredientCategory(id: $deleteIngredientCategoryId, newId: $newId) {
            id
        }
    }
`;
